import { autofocusAll } from './index';

export const scrollToQuestionnaire = (context, history, redirectAfterSurgery) => {
  const path = '/member/join/support';

  if (context.auth.isLoggedIn()) {
    context.auth.userUpdate({ aborted_questionnaire: new Date() }, (user) => {
      const match = user.current_profile.mentor_match;
      redirectAfterSurgery(user.current_profile.procedures[0], match?.surgery, history, path, match);
    });
  } else {
    const element = document.querySelector('#questionnaire');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });

      const timer = setTimeout(() => {
        autofocusAll();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }
};

import React, { useContext } from 'react';
import { useQuestionnaireModal } from '../../../../utils/questionnaire_modal_context';
import { useHistory } from 'react-router-dom';
import Context from '../../../../utils/context';
import { redirectAfterSurgery } from '../../../questionnaire/discussions';
import styles from './index.module.css';

const QuestionnaireModal = ({}) => {
  const context = useContext(Context);
  const history = useHistory();
  const path = '/member/join/support';
  const { isModalVisible } = useQuestionnaireModal();

  if (!isModalVisible) return null;

  const handleRedirectToQuestionnaire = () => {
    // update the user to say that they aborted the questionnaire
    context.auth.userUpdate({ aborted_questionnaire: new Date() }, (user) => {
      const match = user.current_profile.mentor_match;
      redirectAfterSurgery(user.current_profile.procedures[0], match?.surgery, history, path, match);
    });
  };

  return (
    <div className={`${styles.questionnaire_modal_container} platform-modal container-fluid`}>
      <div className={`${styles.q_modal}`}>
        <div className={styles.modal_body_wrap}>
          <div className={`${styles.questionnaire_modal_banner}`}>
            <p className={`${styles.nb_para}`}>You're almost there!</p>
          </div>
          <div className={`${styles.content}`}>
            Finish your questionnaire to chat with a real patient or caregiver and learn from their experience.
          </div>
          <div className={'row justify-content-center'}>
            <div className={`${styles.cta_wrap}`}>
              <div className={'marg-right-20'}>
                <button onClick={handleRedirectToQuestionnaire}>Find My Mentor</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireModal;

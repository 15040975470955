import React, { useState, useEffect, useContext } from 'react';
import { Switch, useParams, useRouteMatch, Route, useHistory } from 'react-router-dom';
import { getPatientPartner } from '../../../../api/patient_partners';
import { arrayCompact } from '../../../../helpers';
import {
  getSurgeonReferredMentorMatch,
  updateSurgeonReferredMentorMatch,
  addMatchedMentor,
} from '../../../../api/surgeon_referred_mentor_matches';
import HealthcareJourney from '../../../questionnaire/patient_partner/healthcare_journey';
import Context from '../../../../utils/context';
import PatientGender from '../../../questionnaire/patient_partner/patient_gender';
import Age from '../../../questionnaire/age/age';
import HowAreYouFeeling from '../../../questionnaire/cosmetic/how_are_you_feeling';
import LoadingScreen from '../../../loading_screen';
import MentorMatch from './mentor_match';
import styles from '../index.module.css';
import WhatSurgery from '../../../questionnaire/patient_partner/what_surgery';
import OptionsAndOther from '../../../questionnaire/choose_an_option';
import UterineFibroidsPreSurvey from './uterine_fibroids';
import InsuranceSelection from './insurance_selection';
import InsuranceSelectionAus from './insurance_selection_aus';
import InsuranceCarrier from './insurance_carrier';
import PatientOrCaregiver from './myelofibrosis/patient_or_caregiver';
import MfDiagnosis from './myelofibrosis/mf_diagnosis';
import MfInformation from './myelofibrosis/mf_information';
import MfUnderstanding from './myelofibrosis/mf_understanding';
import MfDiagnosisTime from './myelofibrosis/mf_diagnosis_time';
import MfPreviousSymptomManagement from './myelofibrosis/mf_previous_symptom_management';
import MfCurrentSymptomManagement from './myelofibrosis/mf_current_symptom_management';
import MfClinicalTrial from './myelofibrosis/mf_clinical_trial';
import MfTreatmentGoals from './myelofibrosis/mf_treatment_goals';
import MfQolAffect from './myelofibrosis/mf_qol_affect';

export default function PreConversationQuestionnaire({}) {
  const context = useContext(Context);
  const { id } = useParams();
  const [surgeonReferredMentorMatch, setSurgeonReferredMentorMatch] = useState({});
  const [currentSurgery, setCurrentSurgery] = useState('');
  const [currentGender, setCurrentGender] = useState(
    context.auth.isLoggedIn() ? context.auth.getCurrentUser().gender : null
  );
  const [currentBirthday, setCurrentBirthday] = useState(
    context.auth.isLoggedIn() ? context.auth.getCurrentUser().date_of_birth : null
  );
  const [currentJourney, setCurrentJourney] = useState('');
  const [currentlyFeeling, setCurrentlyFeeling] = useState('');
  const [conversationTopics, setConversationTopics] = useState({});
  const [wouldLikeConsultation, setWouldLikeConsultation] = useState('');
  const [reactiv8PatientType, setReactiv8PatientType] = useState('');
  const [military, setMilitary] = useState('');
  const [insuranceSelection, setInsuranceSelection] = useState('');
  const [insuranceCarrier, setInsuranceCarrier] = useState('');
  const [mfPatientOrCaregiver, setMfPatientOrCaregiver] = useState('');
  const [mfDiagnosis, setMfDiagnosis] = useState('');
  const [mfUnderstanding, setMfUnderstanding] = useState('');
  const [mfInformation, setMfInformation] = useState('');
  const [mfDiagnosisTime, setMfDiagnosisTime] = useState('');
  const [mfPreviousSymptomsManagement, setMfPreviousSymptomsManagement] = useState();
  const [mfCurrentSymptomsManagement, setMfCurrentSymptomsManagement] = useState();
  const [mfClinicalTrial, setMfClinicalTrial] = useState();
  const [mfQolAffect, setMfQolAffect] = useState();
  const [mfTreatmentGoals, setMfTreatmentGoals] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [patientPartner, setPatientPartner] = useState(null);
  let { path } = useRouteMatch();
  path = path.replace(`:id`, id);

  const [availableSurgeries, setAvailableSurgeries] = useState([]);
  let history = useHistory();

  useEffect(() => {
    getSurgeonReferredMentorMatch(id, (data) => {
      setSurgeonReferredMentorMatch(data);
      setCurrentSurgery(data.surgery);
      setCurrentJourney(data.healthcare_journey);
      setCurrentlyFeeling(data.currently_feeling);
      setConversationTopics(data.conversation_topics);
      if (data.patient_partner) {
        setPatientPartner(data.patient_partner);
      }
      setAvailableSurgeries(data.available_surgeries);
      setReactiv8PatientType(data.reactiv8_patient_type);
      setMilitary(data.military);
      setInsuranceSelection(data.insurance_selection);
      setInsuranceCarrier(data.insurance_carrier);
      setMfPatientOrCaregiver(data.mf_patient_or_caregiver);
      setMfDiagnosis(data.mf_diagnosis);
      setMfUnderstanding(data.mf_understanding);
      setMfDiagnosisTime(data.mf_diagnosis_time);
      setMfPreviousSymptomsManagement(data.mf_previous_symptoms_management);
      setMfCurrentSymptomsManagement(data.mf_current_symptoms_management);
      setMfClinicalTrial(data.mf_clinical_trial);
      setMfQolAffect(data.mf_qol_affect);

      const afterLoad = () => {
        if (window.location.pathname && window.location.pathname.endsWith('pre-survey')) {
          history.push(`${path}/gender`);
        }
        setIsLoading(false);
      };

      if (data.patient_partner_id) {
        getPatientPartner(data.patient_partner_id, (r) => {
          setPatientPartner(r);
          afterLoad();
        });
      } else {
        afterLoad();
      }
    });
  }, []);

  const update = (params) => {
    updateSurgeonReferredMentorMatch(id, params, (data) => setSurgeonReferredMentorMatch(data));
  };

  const findMentor = () => {
    addMatchedMentor((data) => {
      setPatientPartner(data);
      sessionStorage.setItem('patientPartner', JSON.stringify(data));
      history.push(`${path}/mentor-match`);
    });
  };

  const capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  let questionNumber = null;
  let steps = ['gender', 'age', 'healthcare-journey', 'currently-feeling', 'conversation-topics'];
  let procedureWord = 'procedure';

  let conversationTopicsOptions = [
    `Pros and cons of the ${procedureWord}`,
    `${capitalize(procedureWord)} preparation tips`,
    'Recovery tips',
    'Tips for finding the best doctor',
  ];

  if (surgeonReferredMentorMatch && surgeonReferredMentorMatch.surgeon) {
    if (surgeonReferredMentorMatch.surgeon.slug === 'new-york-bariatric-group') {
      steps = ['gender', 'age', 'healthcare-journey', 'conversation-topics'];
    } else if (surgeonReferredMentorMatch.surgeon.slug === 'reactiv8-australia') {
      steps = ['gender', 'age', 'insurance-select-aus', 'conversation-topics'];
      conversationTopicsOptions = [
        `Therapy preparation tips`,
        `Unit and therapy sensation`,
        'Recovery process and recommendations',
        'Previous symptoms and result details',
        'General questions and support',
      ];
    } else if (surgeonReferredMentorMatch.surgeon.slug === 'reactiv8') {
      procedureWord = 'therapy';
      if (insuranceSelection === 'Commercial Insurance') {
        steps = [
          'gender',
          'age',
          'patient-type',
          'military',
          'insurance-selection',
          'insurance-carrier',
          'conversation-topics',
        ];
      } else {
        steps = ['gender', 'age', 'patient-type', 'military', 'insurance-selection', 'conversation-topics'];
      }
      conversationTopicsOptions = [
        `Pros and cons of the ${procedureWord}`,
        `${capitalize(procedureWord)} preparation tips`,
        'How ReActiv8 therapy can help',
        'Authorization process and helpful tips',
      ];
    } else if (surgeonReferredMentorMatch.surgeon.slug === 'vonjo') {
      conversationTopicsOptions = [
        `Ways to cope with an MF diagnosis`,
        `Tips for managing symptoms`,
        'Personal insight on emotional impact and how to deal with it',
        'Advice on creating/maintaining a support system',
        'Specific questions about treatment',
      ];
      steps = [
        'gender',
        'age',
        'mf-patient-caregiver',
        'mf-diagnosis',
        'mf-understanding',
        'mf-information',
        'mf-length',
        'mf-previous-symptoms-mgmt',
        'mf-current-symptoms-mgmt',
        'mf-clinical-trial',
        'mf-qol-affect',
        'mf-treatment-goals',
        'conversation-topics',
      ];
    }
  }

  let numberOfQuestions = steps.length + 1;

  steps.forEach((r, i) => {
    if (window.location.pathname.includes(r)) {
      questionNumber = i + 2;
    }
  });

  if (
    questionNumber &&
    surgeonReferredMentorMatch &&
    surgeonReferredMentorMatch.surgeon &&
    surgeonReferredMentorMatch.surgeon.slug === 'reactiv8'
  ) {
    numberOfQuestions = steps.length;
    questionNumber = questionNumber - 1;
  }

  let nextStep = steps[steps.findIndex((r) => window.location.pathname.includes(r)) + 1];
  if (!nextStep) {
    nextStep = 'conversation-topics';
  }
  const previousStep = steps[steps.findIndex((r) => window.location.pathname.includes(r)) - 1];

  return (
    <div className={styles.questionnaire_ottr} style={{ backgroundColor: '#f5f7f9', maxWidth: '100%' }}>
      <div id='questionnaire' className='container h-100'>
        <div className='row justify-content-center h-100'>
          <div className='col h-100'>
            <div className={`row ${styles.introQuestion} h-100`}>
              <div className='col-sm-2'></div>
              <div className='col-sm-8'>
                {questionNumber && questionNumber <= numberOfQuestions && (
                  <h5 style={{ width: '100%', marginBottom: '30px', textAlign: 'center', fontSize: '16px' }}>
                    {questionNumber} of {numberOfQuestions}
                  </h5>
                )}
                {isLoading ? (
                  <LoadingScreen />
                ) : (
                  <Switch>
                    <Route exact path={`${path}`}>
                      <WhatSurgery
                        para={'This will help us share relevant and relatable information about your surgery.'}
                        onSubmit={(value) => {
                          setCurrentSurgery(value[0]);
                          update({ surgery: value[0] });
                          history.push(`${path}/gender`);
                        }}
                        onBack={() => {
                          history.push(`/surgeon/${surgeonReferredMentorMatch.surgeon.slug}/mentor-match`);
                        }}
                        selected={[currentSurgery]}
                        choices={availableSurgeries ? [...availableSurgeries] : []}
                        label={'Select surgery'}
                      />
                    </Route>
                    <Route exact path={`${path}/gender`}>
                      <PatientGender
                        para='We find that patients and mentors who identify as the same gender form better connections.'
                        answers={{ gender: currentGender }}
                        onSubmit={(answers) => {
                          setCurrentGender(answers.gender);
                          context.auth.userUpdate({ gender: answers.gender }, () => history.push(`${path}/age`));
                        }}
                        onBack={
                          previousStep
                            ? () => {
                                history.push(`${path}`);
                              }
                            : null
                        }
                        gtm={''}
                      />
                    </Route>
                    <Route exact path={`${path}/age`}>
                      <Age
                        title='What is your birthday?'
                        para='Receive recommendations and resources relatable to your age group.'
                        answers={{ date_of_birth: currentBirthday }}
                        onSubmit={(answers) => {
                          setCurrentBirthday(answers.date_of_birth);
                          context.auth.userUpdate({ date_of_birth: answers.date_of_birth }, () =>
                            history.push(`${path}/${nextStep}`)
                          );
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                        gtm={''}
                        cta='Next'
                      />
                    </Route>
                    <Route exact path={`${path}/healthcare-journey`}>
                      <HealthcareJourney
                        allowOther={false}
                        answers={{ currentJourney: currentJourney }}
                        onSubmit={(answers) => {
                          setCurrentJourney(answers.currentJourney[0]);
                          update({ healthcare_journey: answers.currentJourney[0] });
                          if (steps.includes('currently-feeling')) {
                            history.push(`${path}/currently-feeling`);
                          } else {
                            history.push(`${path}/conversation-topics`);
                          }
                        }}
                        onBack={() => {
                          history.push(`${path}/age`);
                        }}
                      />
                    </Route>
                    <Route exact path={`${path}/currently-feeling`}>
                      <HowAreYouFeeling
                        answers={{ currentlyFeeling: currentlyFeeling }}
                        numberRating={true}
                        onSubmit={(answers) => {
                          setCurrentlyFeeling(answers.currentlyFeeling);
                          update({ currently_feeling: answers.currentlyFeeling });
                          history.push(`${path}/conversation-topics`);
                        }}
                        icons={[
                          { text: 'Very nervous' },
                          { text: 'Nervous' },
                          { text: 'Unsure' },
                          { text: 'Excited' },
                          { text: 'Very excited' },
                        ]}
                        textLabels={['Very Nervous', 'Nervous', 'Unsure', 'Excited', 'Very Excited']}
                        onBack={() => {
                          history.push(`${path}/healthcare-journey`);
                        }}
                      />
                    </Route>
                    <Route exact path={`${path}/conversation-topics`}>
                      <OptionsAndOther
                        answers={{ conversationTopics: conversationTopics }}
                        keyForAnswer={'conversationTopics'}
                        onSubmit={(answers) => {
                          update({ conversation_topics: answers['conversationTopics'] });
                          setConversationTopics(answers['conversationTopics']);
                          if (steps.includes('consultation')) {
                            history.push(`${path}/consultation`);
                          } else {
                            history.push(`${path}/loading`);
                            const timer = setTimeout(() => {
                              findMentor();
                            }, 3000);
                            return () => clearTimeout(timer);
                          }
                        }}
                        onBack={() => {
                          history.push(`${path}/${steps[steps.length - 2]}`);
                        }}
                        choices={conversationTopicsOptions}
                        title={`What would you like to talk to your mentor about? (Select all that apply.)`}
                        para={`We’ll let your mentor know some topics you’d like to talk about.`}
                        otherItemLabel={`reasons`}
                        ctaTxt={steps.includes('consultation') ? 'Next' : 'Submit'}
                      />
                    </Route>
                    <Route exact path={`${path}/consultation`}>
                      <OptionsAndOther
                        answers={{ wouldLikeConsultation: wouldLikeConsultation }}
                        keyForAnswer={'wouldLikeConsultation'}
                        onSubmit={(answers) => {
                          update({ would_like_consultation: answers['wouldLikeConsultation'] });
                          setWouldLikeConsultation(answers['wouldLikeConsultation']);
                          history.push(`${path}/loading`);
                          const timer = setTimeout(() => {
                            findMentor();
                          }, 3000);
                          return () => clearTimeout(timer);
                        }}
                        onBack={() => {
                          history.push(`${path}/conversation-topics`);
                        }}
                        choices={['Yes', 'No']}
                        title={`Would you like a doctor from New York Bariatric Group to reach out to you about a consultation?`}
                        para={`They will call the phone number you listed to schedule an appointment.`}
                        showOther={false}
                        ctaTxt='Submit'
                        optional
                        selectOne
                      />
                    </Route>
                    <Route exact path={`${path}/patient-type`}>
                      <OptionsAndOther
                        answers={{ reactiv8PatientType: reactiv8PatientType }}
                        keyForAnswer={'reactiv8PatientType'}
                        onSubmit={(answers) => {
                          update({ reactiv8_patient_type: answers['reactiv8PatientType'] });
                          setReactiv8PatientType(answers['reactiv8PatientType']);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                        choices={['Clinical Trial', 'Commercial', 'Not sure']}
                        title={`Specify your patient type.`}
                        para={`We’ll match you with a mentor who’s gone through the same journey.`}
                        showOther={false}
                        ctaTxt='Next'
                        optional
                        selectOne
                      />
                    </Route>
                    <Route exact path={`${path}/military`}>
                      <OptionsAndOther
                        answers={{ military: military }}
                        keyForAnswer={'military'}
                        onSubmit={(answers) => {
                          update({ military: answers['military'] });
                          setMilitary(answers['military']);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                        choices={['Currently Active', 'Veteran', 'N/A']}
                        title={`Have you served in the military?`}
                        para={``}
                        showOther={false}
                        ctaTxt='Next'
                        optional
                        selectOne
                      />
                    </Route>
                    <Route exact path={`${path}/insurance-selection`}>
                      <InsuranceSelection
                        value={insuranceSelection}
                        onSubmit={(answers) => {
                          update({ insurance_selection: answers['insuranceSelection'] });
                          setInsuranceSelection(answers['insuranceSelection']);
                          if (answers['insuranceSelection'] === 'Commercial Insurance') {
                            history.push(`${path}/insurance-carrier`);
                          } else {
                            history.push(`${path}/conversation-topics`);
                          }
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route exact path={`${path}/insurance-select-aus`}>
                      <InsuranceSelectionAus
                        value={insuranceSelection}
                        onSubmit={(answers) => {
                          update({ insurance_selection: answers['insuranceSelection'] });
                          setInsuranceSelection(answers['insuranceSelection']);
                          history.push(`${path}/conversation-topics`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route exact path={`${path}/insurance-carrier`}>
                      <InsuranceCarrier
                        value={insuranceCarrier}
                        onSubmit={(answers) => {
                          update({ insurance_carrier: answers['insuranceCarrier'] || answers['other'] });
                          setInsuranceCarrier(answers['insuranceCarrier'] || answers['other']);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route exact path={`${path}/mf-patient-caregiver`}>
                      <PatientOrCaregiver
                        value={mfPatientOrCaregiver}
                        onSubmit={(answers) => {
                          update({ mf_patient_or_caregiver: answers['mfPatientOrCaregiver'] || answers['other'] });
                          setMfPatientOrCaregiver(answers['mfPatientOrCaregiver'] || answers['other']);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route exact path={`${path}/mf-diagnosis`}>
                      <MfDiagnosis
                        value={mfDiagnosis}
                        onSubmit={(answers) => {
                          update({ mf_diagnosis: answers['mfDiagnosis'] || answers['other'] });
                          setMfDiagnosis(answers['mfDiagnosis'] || answers['other']);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route exact path={`${path}/mf-understanding`}>
                      <MfUnderstanding
                        value={mfUnderstanding}
                        onSubmit={(answers) => {
                          update({ mf_understanding: answers['mfUnderstanding'] || answers['other'] });
                          setMfUnderstanding(answers['mfUnderstanding'] || answers['other']);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route exact path={`${path}/mf-information`}>
                      <MfInformation
                        value={mfInformation}
                        onSubmit={(answers) => {
                          let result = answers['mfInformation'].concat([answers['other']]);
                          result = arrayCompact(result);
                          update({ mf_information: result });
                          setMfInformation(result);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route exact path={`${path}/mf-length`}>
                      <MfDiagnosisTime
                        value={mfDiagnosisTime}
                        onSubmit={(answers) => {
                          update({ mf_diagnosis_time: answers['mfDiagnosisTime'] || answers['other'] });
                          setMfDiagnosisTime(answers['mfDiagnosisTime'] || answers['other']);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route exact path={`${path}/mf-previous-symptoms-mgmt`}>
                      <MfPreviousSymptomManagement
                        value={mfPreviousSymptomsManagement}
                        onSubmit={(result) => {
                          update({ mf_previous_symptoms_management: result });
                          setMfPreviousSymptomsManagement(result);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route exact path={`${path}/mf-current-symptoms-mgmt`}>
                      <MfCurrentSymptomManagement
                        value={mfCurrentSymptomsManagement}
                        onSubmit={(result) => {
                          update({ mf_current_symptoms_management: result });
                          setMfCurrentSymptomsManagement(result);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                        para={`Which medication/s are you (or the patient) currently prescribed to manage Myelofibrosis (MF)? Using the dropdown selection, please specify how long you have been using each medication.`}
                      />
                    </Route>
                    <Route exact path={`${path}/mf-clinical-trial`}>
                      <MfClinicalTrial
                        value={mfClinicalTrial}
                        onSubmit={(answers) => {
                          update({ mf_clinical_trial: answers['mfClinicalTrial'] });
                          setMfClinicalTrial(answers['mfClinicalTrial']);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route exact path={`${path}/mf-qol-affect`}>
                      <MfQolAffect
                        value={mfQolAffect}
                        onSubmit={(answers) => {
                          let result = answers['mfQolAffect'].concat([answers['other']]);
                          result = arrayCompact(result);
                          update({ mf_qol_affect: result });
                          setMfQolAffect(result);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route exact path={`${path}/mf-treatment-goals`}>
                      <MfTreatmentGoals
                        value={mfTreatmentGoals}
                        onSubmit={(answers) => {
                          update({ mf_treatment_goals: answers['mfTreatmentGoals'] || answers['other'] });
                          setMfTreatmentGoals(answers['mfTreatmentGoals'] || answers['other']);
                          history.push(`${path}/${nextStep}`);
                        }}
                        onBack={() => history.push(`${path}/${previousStep}`)}
                      />
                    </Route>
                    <Route path={`${path}/uterine-fibroids`}>
                      <UterineFibroidsPreSurvey
                        path={`${path}/uterine-fibroids`}
                        rootPath={path}
                        findMentor={findMentor}
                        surgeonReferredMentorMatch={surgeonReferredMentorMatch}
                        update={update}
                      />
                    </Route>
                    <Route exact path={`${path}/loading`}>
                      <LoadingScreen
                        title={'Finding your mentor...'}
                        content={
                          'Get ready to meet a real patient who will answer your questions and support you through your journey!'
                        }
                      />
                    </Route>
                    <Route exact path={`${path}/mentor-match`}>
                      {patientPartner ? (
                        <MentorMatch
                          patientPartner={patientPartner}
                          whatsNextMessage={(p) => {
                            return `Send your first message to ${p.name} and get answers to questions about the procedure, recovery, and their experience.`;
                          }}
                          callback={(conversation) => {
                            update({ conversation_id: conversation.id });
                            history.push(`/member/conversations/${conversation.uuid}`);
                          }}
                        />
                      ) : (
                        <LoadingScreen
                          title={'Finding your mentor...'}
                          content={
                            'Get ready to meet a real patient who will answer your questions and support you through your journey!'
                          }
                        />
                      )}
                    </Route>
                  </Switch>
                )}
              </div>
              <div className='col-sm-2'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
